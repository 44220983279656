/* You can add global styles to this file, and also import other style files */

/*!
 * Bootstrap v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack

@import "./style_variables.scss";
@import "~bootstrap/scss/maps";

@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack

// Select2
@import "~select2-bootstrap-5-theme/src/select2-bootstrap-5-theme.scss";
@import "~select2/src/scss/core";

@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
body{
    -webkit-font-smoothing: antialiased; 
    // color: #000;
    font-family: 'Ubuntu', sans-serif;
    // font-family: 'Ubuntu', sans-serif;
    // font-size: 16px;
    // line-height: 1.3;
}
*{
    word-break: keep-all;
}
p{
    font-family: "Karla", Helvetica, Arial, Verdana, sans-serif;
}
strong {
    font-weight: 800 !important;
  }
.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fs-15 {
    font-size: .95rem;
}
.fs-14 {
    font-size: .85rem;
}
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

.lh-0 {
  line-height: 1;
}
.lh-1 {
  line-height: 1.25rem;
}
.lh-2 {
  line-height: 1.5rem;
}
.lh-3 {
  line-height: 1.75rem;
}
.lh-4 {
  line-height: 2rem;
}
.lh-5 {
  line-height: 2.5rem;
}


.pe-pointer {
    cursor: pointer;
}

.text-muted-light {
    color: $gray-300
}

.text-justify {
  text-align: justify;
}

.rounded-15 {
    border-radius: 15px;
}

img {
    object-fit: cover !important;
}
a {
    cursor: pointer;
}
.bg-light-blue {
    background-color: #f2f6fa;
}
.nav-link.bg-light.active {
    background-color: $white !important;
}

.section-header {
    position: relative;

    &:after {
        position: absolute;
        content: '';
        height: 0px;
        width: 100%;
        left: 0px;
        bottom: -100px;
        border-width: 50px calc(100vw - 0px);
        border-style: solid;
        border-color: $gray-200 $gray-200 transparent transparent;
    }
}

// BOOTSTRAP OVERRIDE
.dropdown-menu {
    padding: 0 !important;
}
.dropdown-item {
    padding: .8rem !important;
    font-size: 14px;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: #fff;
    color: #000;
}
.form-control {
    font-size: .85rem;
    line-height: 1.6rem;
}
.text-break {
    white-space: break-spaces;
}
.nav-link {
  color: #495057 !important;
  &.active, &:hover{
    color: #a251bd !important;
  }
}
.btn {
    font-weight: 500!important;
}
.btn-primary {
    font-weight: 500!important;
}
.btn-link {
    font-weight: 400;
    color: #a251bd;
    text-decoration: none !important;
}
.btn-link:hover {

    background-color: #a251bd;
    color: #fff;
}

.btn {
    &.btn-rounded {
        border-radius: 20px;
    }
    &.btn-round {
        border-radius: 50%;
        min-width: 40px;
        min-height: 40px;
    }
    &.btn-link {
        text-decoration: none;
    }
    &.btn-cta {
        padding: 20px;
        box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
        &:active {
            box-shadow: inset 0 0 15px 0 rgb(0 0 0 / 15%);
        }
    }

    &.btn-viewer-header {
        background-color: $gray-300;
        border: $gray-300;
        font-weight: 700;
        padding-block: 10px;
        padding-inline: 15px;

        &:hover {
            background-color: $primary;
        }
    }
}

.bg-purple {
    background-color: $purple;
}

.badge.badge-item-type {
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    left: 10px;
    top: 10px;
}

.container {
  max-width: 1100px !important;
}



.login-modal {
    padding-top: 80px;
    @media (max-width: 767px) {
        padding-top: 30px;
    }
}


.font-family-karla, .ff-karla {
    font-family: $font-content-karla;
}
.font-family-open-sans, .ff-open-sans, .ff-os{
    font-family: $font-content-open-sans;
}

.form-select {
    color: #000 !important;
    font-size: $font-size-sm;
    font-family: "Karla", Helvetica, Arial, Verdana, sans-serif;
}


body > .dropdown {
  .dropdown-menu.dropdown-menu-center {
      -webkit-transform: translate(-39%, 5px) !important;
      -o-transform: translate(-39%, 5px) !important;
      transform: translate(-39%, 5px) !important;
  }
}


.dropdown {
  .dropdown-menu.dropdown-menu-movie {
    min-width: 200px;
    text-align: center;
    .dropdown-item {
      // color: $purple-600;
      color: $menu-text;
      border-bottom: 1px solid #f2f6fb
    }
  }

  .dropdown-menu {
    &.dropdown-menu-center {
      -webkit-transform: translate(-20%, 42px) !important;
      -o-transform: translate(-20%, 42px) !important;
      transform: translate(-20%, 42px) !important;

      &.dropdown-bubble {
        &:before,
        &:after{
          left: initial;
          right: 50%;
          transform: translate(50%);
        }
      }
    }

    &.dropdown-bubble {
      margin-top: .1em !important;
      &:before,
      &:after{
        content: ' ';
        display: block;
        border-style: solid;
        border-width: 0 .4em .4em .4em;
        border-color: transparent;
        position: absolute;
        left: 1em;
        top: -0.4em!important;
      }

      &:before {
        top: -.4em;
        border-bottom-color: rgba(24, 14, 14, 0.2);
      }

      &:after {
        top: -.3em;
        border-bottom-color: #fff;
      }

      &.dropdown-bubble-right-arrow {
        &:before,
        &:after{
          left: initial;
          right: .75em;
        }
      }
    }
  }

  .dropdown-item:hover{
      border-radius: 0.4rem;
      background-color: #f2f6fb;
  }

  li:last-child a.dropdown-item{
      border-bottom: none !important;

  }
  li:last-child button.dropdown-item{
      border-bottom: none !important;

  }
}


// special tags
// #special tags circle circlity
.special-tag-outer-container {
    height: auto;
    margin: 0 auto;
    min-height: 160px;
    min-width: $special-tag-outer-width-base;
    position: relative;
    width: $special-tag-outer-width-base;
    h3 {
        font-family: $font-base-ubuntu;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.4;
        margin: 0;
        padding: 0;
        word-wrap: break-word;
    }
    h4 {
        color: $gray-500;
        font-family: $font-content-karla;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.4;
        margin: 0 0 10px;
        padding: 0;
    }

    // here we generate our different items' color versions,
    // and some other stuff
    @for $i from 1 through 8 {
        $level-index: $i - 1;
        &.level-#{ $level-index } {
            $current-color:rgba(nth($level-color-list, $i), 1);
            // #svg circles
            .special-tag-background {
              background-image: url('assets/img/special-tags/special-tag-background/special-tag-background-level-#{ $level-index }.svg');
            }
            .special-tag-circle-full {
              background-image: url('assets/img/special-tags/special-tag-circle-full/special-tag-circle-full-level-#{ $level-index }.svg');
            }
            .special-tag-circle-broad-stroke {
              background-image: url('assets/img/special-tags/special-tag-circle-broad-stroke/special-tag-circle-broad-stroke-level-#{ $level-index }.svg');
            }
            .special-tag-circle-thin-stroke {
              background-image: url('assets/img/special-tags/special-tag-circle-thin-stroke/special-tag-circle-thin-stroke-level-#{ $level-index }.svg');
            }
            // #color titles
            h3 {
              color: #{ $current-color };
            }
        }
    }
}
.special-tag-background,
.special-tag-circle {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
}
.special-tag-background {
    height: $special-tag-base-circle-dimensions;
    margin: 0 auto 15px;
    position: relative;
    width: $special-tag-base-circle-dimensions;
}
.special-tag-circle {
    position: absolute;
    z-index: 12;
}
.special-tag-circle-1 {
    left: -1px;
    top: 67px;
}
.special-tag-circle-2 {
    left: -8px;
    top: 42px;
}
.special-tag-circle-3 {
    left: -1px;
    top: 18px;
}
.special-tag-circle-4 {
    left: 17px;
    top: 0;
}
.special-tag-circle-5 {
    left: 43px;
    top: -7px;
}
.special-tag-circle-6 {
    left: 69px;
    top: 0;
}
.special-tag-circle-7 {
    left: 87px;
    top: 18px;
}
.special-tag-circle-8 {
    left: 93px;
    top: 42px;
}
.special-tag-circle-9 {
    left: 87px;
    top: 67px;
}
.special-tag-circle-10 {
    left: 69px;
    top: 86px;
}
.special-tag-circle-11 {
    left: 43px;
    top: 93px;
}
.special-tag-circle-12 {
    left: 17px;
    top: 85px;
}
.special-tag-circle-full {
    height: $special-tag-full-circle-dimensions;
    width: $special-tag-full-circle-dimensions;
}
.special-tag-circle-broad-stroke {
    height: $special-tag-broad-circle-dimensions;
    width: $special-tag-broad-circle-dimensions;
}
.special-tag-circle-thin-stroke {
    height: $special-tag-thin-circle-dimensions;
    width: $special-tag-thin-circle-dimensions;
}
// #video profile special tags row
.special-tags-row-video-profile {
    padding-top: 20px;
    .special-tag-col-container {
        float: left;
        min-height: 225px;
        padding-bottom: 35px;
        width: 21%;
    }
}
// #learn more pop up
.special-tag-popup {
    padding-top: $special-tag-modal-top-padding;
    width: $special-tag-popup-width;
    @media all and (max-width: 764px){
        float: left;
        width: 100%;
    }
}
.special-tag-text-popup {
    margin-left: $special-tag-popup-width + 10;
    overflow: hidden;
    padding-top: $special-tag-modal-top-padding;
    .description--large {
        margin-bottom: 25px;
    }

    @media all and (max-width: 764px){
        float: left;
        margin-left: 0;
    }
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: 1000px;
    }
}
.modal-600{
    max-width: 600px;
}

.modal-700{
    max-width: 700px;
}
.modal-800{
    max-width: 800px;
}
.modal-850{
    max-width: 850px;
}
.modal-820{
    max-width: 820px;
}
.modal-900{
    max-width: 900px;
}
.modal-1000{
    max-width: 1000px;
}
.modal-1200{
    max-width: 1200px;
}
.modal-1300 {
    min-width: calc(90%) !important;
}
// ############################ MODALS ################################
.modal-label {
    font-size: 14px!important;
    font-family: "Ubuntu",Helvetica,Arial,Verdana,sans-serif;
    margin-top: 10px;
    margin-bottom: 5px;
}
.default-modal2 {
    z-index: 1080;
    .modal-content{
        // min-height: 300px;
    }
    .modal-header {
        background-color: #f8fcff;
        padding:8px;
    }
    .modal-dialog {
        position: relative;
        padding-top: 25px;
        .modal-close {
            position: absolute;
            top: -30px;
            left: 50%;
            transform: translateX(-50%);
            color: black !important;
            cursor: pointer;
            background-color: #fff;
            border-radius: 8px;
            padding-left: 5px;
            padding-right: 5px;
        }
    }
    .modal-label {
        font-size: 14px!important;
        font-family: "Ubuntu",Helvetica,Arial,Verdana,sans-serif;
        margin-top: 10px;
    }
    .modal-footer {

    }
    .modal-text-description{
        font-family: "Karla", Helvetica, Arial, Verdana, sans-serif;
        font-size: 16px!important;
    }
}
.default-modal {
    .modal-content{
        // min-height: 300px;
    }
    .modal-header {
        background-color: #f8fcff;
        padding:8px;
    }
    .modal-dialog {
        position: relative;
        padding-top: 25px;
        .modal-close {
            position: absolute;
            top: -30px;
            left: 50%;
            transform: translateX(-50%);
            color: black !important;
            cursor: pointer;
            background-color: #fff;
            border-radius: 8px;
            padding-left: 5px;
            padding-right: 5px;
        }
    }
    .modal-label {
        font-size: 14px!important;
        font-family: "Ubuntu",Helvetica,Arial,Verdana,sans-serif;
        margin-top: 10px;
    }
    .modal-footer {

    }
    .modal-text-description{
        font-family: "Karla", Helvetica, Arial, Verdana, sans-serif;
        font-size: 16px!important;
    }
}
.common-separator {
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid #adb5bd;
    margin: 25px 0;
    padding: 0;
}

.small-form-text {
    color: #636e72;
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: 10px;
    margin-top: -2px;
    padding-left: 26px;
}



.fancy-radio-checkbox {
  height: 16px;
  min-width: 16px;
  cursor: pointer;
  display: block !important;
  font-family: "Ubuntu",Helvetica,Arial,Verdana,sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  margin: 0 0 10px;
  padding-left: 26px;
  position: relative;
  text-align: left;

  .fancy-input {
    height: 16px;
    left: 0;
    margin-right: 8px;
    margin: 0 !important;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 16px;
    z-index: 12;
  }

  .fancy-graphic {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 16px;
    left: 0;
    position: absolute;
    top: 0;
    width: 16px;
    z-index: 0;
  }

  input[type="radio"]:checked+.fancy-graphic {
    background-image: url('data:image/svg+xml;base64, PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iNDMuMiA0My4yIDE3LjUgMTcuNSIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyA0My4yIDQzLjIgMTcuNSAxNy41IiB4bWw6c3BhY2U9InByZXNlcnZlIj48Zz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDIzLjAwMDAwMCwgLTIxMy4wMDAwMDApIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0MzcuMDAwMDAwLCA2OS4wMDAwMDApIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMC4wMDAwMDAsIDE1OC4wMDAwMDApIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgMjkuMDAwMDAwKSI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsIDEuMDAwMDAwKSI+PGVsbGlwc2UgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjQTI1MUJEIiBzdHJva2Utd2lkdGg9IjEuNSIgY3g9IjgiIGN5PSI4IiByeD0iOCIgcnk9IjgiIC8+PGVsbGlwc2UgZmlsbD0iI0EyNTFCRCIgY3g9IjgiIGN5PSI4IiByeD0iNCIgcnk9IjQiIC8+PC9nPjwvZz48L2c+PC9nPjwvZz48L2c+PC9zdmc+');
  }
  input[type="radio"]+.fancy-graphic {
    background-image: url('data:image/svg+xml;base64, PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iNDYuMiA0Ni4yIDE3LjUgMTcuNSIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyA0Ni4yIDQ2LjIgMTcuNSAxNy41IiB4bWw6c3BhY2U9InByZXNlcnZlIj48Zz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDIwLjAwMDAwMCwgLTI1MC4wMDAwMDApIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0MzcuMDAwMDAwLCA2OS4wMDAwMDApIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMC4wMDAwMDAsIDE1OC4wMDAwMDApIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgNjkuMDAwMDAwKSI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsIDEuMDAwMDAwKSI+PGVsbGlwc2UgZmlsbD0iI0ZGRkZGRiIgc3Ryb2tlPSIjQTI1MUJEIiBzdHJva2Utd2lkdGg9IjEuNSIgY3g9IjgiIGN5PSI4IiByeD0iOCIgcnk9IjgiIC8+PC9nPjwvZz48L2c+PC9nPjwvZz48L2c+PC9zdmc+');
  }

  input[type="checkbox"]:checked+.fancy-graphic {
    background-image: url('data:image/svg+xml;base64, PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8yIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iNjkuNyAxOTMgNTQ1LjggNTQ1LjgiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgNjkuNyAxOTMgNTQ1LjggNTQ1LjgiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxyZWN0IHg9IjIwNi41IiB5PSIzMjkuOCIgZmlsbD0iI0EyNTFCRCIgd2lkdGg9IjI3Mi4xIiBoZWlnaHQ9IjI3Mi4xIi8+PHJlY3QgeD0iOTguOCIgeT0iMjIyLjIiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI0EyNTFCRCIgc3Ryb2tlLXdpZHRoPSI1MCIgd2lkdGg9IjQ4Ny40IiBoZWlnaHQ9IjQ4OC4xIi8+PC9zdmc+');
  }
  input[type="checkbox"]+.fancy-graphic {
    background-image: url('data:image/svg+xml;base64, PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8yIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iNjkuNyAxOTMgNTQ1LjggNTQ1LjgiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgNjkuNyAxOTMgNTQ1LjggNTQ1LjgiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxyZWN0IHg9Ijk4LjgiIHk9IjIyMi4yIiBmaWxsPSJub25lIiBzdHJva2U9IiNBMjUxQkQiIHN0cm9rZS13aWR0aD0iNTAiIHdpZHRoPSI0ODcuNCIgaGVpZ2h0PSI0ODguMSIvPjwvc3ZnPg==');
  }
}




.input-box{
    color: #000 !important;
    font-family: "Karla", Helvetica, Arial, Verdana, sans-serif;
    padding: 0.5em 0.6em;
    display: inline-block;
    border: 1px solid #ccc;
    box-shadow: none;
    outline: none;
    border-radius: 8px;
    vertical-align: middle;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 14px;
    padding: 9px 15px;
}

.subtitle-admin{
    font-family: "Ubuntu",Helvetica,Arial,Verdana,sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 1.43;
}

.modal-btn-width{
    width: 150px;
}
.modal-title {
    font-family: "Ubuntu", Helvetica, Arial, Verdana, sans-serif;
    padding-left: 7px;
    color: #000;
}
.modal-center-override {
    top: 50% !important;
    transform: translateY(-50%) !important;
    -webkit-transform: translateY(-50%) !important;
    -moz-transform: translateY(-50%) !important;
    -o-transform: translateY(-50%) !important;
  }
.text-explanation {
    color: #636e72;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}
.common-form-title {
    font-family: "Ubuntu", Helvetica, Arial, Verdana, sans-serif;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    margin-top: 10px;
}
//////////////////////////
// new classes for modals 
/// /////////////////////
.modal-sub-title{
    color: #000 !important;
    font-family: "Ubuntu", Helvetica, Arial, Verdana, sans-serif;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    margin-top: 10px;
}
.form-label{ // for modal labels
    color: #000 !important;

    font-size: 14px!important;
    font-family: "Ubuntu",Helvetica,Arial,Verdana,sans-serif;
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 500;
}
.form-label-description{
    color: #000 !important;
    font-family: "Karla", Helvetica, Arial, Verdana, sans-serif;
    color: #636e72;
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: 10px;
    margin-top: -2px;
    padding-left: 26px;
}

.form-text-description{
    color: #000 !important;
    font-family: "Karla", Helvetica, Arial, Verdana, sans-serif;
    font-size: 14px!important;
}
.short-form-description{
    color: #000 !important;
    font-family: "Karla", Helvetica, Arial, Verdana, sans-serif;
    font-size: 14px!important;
}
.short-description{
    font-family: "Karla", Helvetica, Arial, Verdana, sans-serif;
}
.btn-success {
    color: #fff !important;
    font-family: "Ubuntu",Helvetica,Arial,Verdana,sans-serif;
}
.btn-danger{
    font-family: "Ubuntu",Helvetica,Arial,Verdana,sans-serif;
}
.btn-outline-primary{
    font-family: "Ubuntu",Helvetica,Arial,Verdana,sans-serif;
}
.dropdown-item {
    font-size: 13.3px;
    font-weight: normal;
    color: #50366e;
    border-bottom: 1px solid #f2f6fb;
    border-bottom: none;
}

// ############################ END MODALS ################################

.movie-slanted {
  background: linear-gradient(3deg, #1b3b5b 0px, #0b1d2c 100%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
  padding-bottom: 150px;
}

.movie-full-background {
  background: url(assets/img/public/public-background.jpg) no-repeat 50% 50%/cover;
  float: left;
  width: 100vw;
  height: 100vh;
}

.modal-backdrop.modal-backdrop-transparent {
  opacity: 0;
}
.playerPoster.fill-width {
    min-width: 100%;
    height: 100% !important;
}
.p-margin{
    p{
        margin-bottom: 0px;
    }
}

.ql-editor p{
    font-size: 17px !important;
}
.ql-editor h6{
    font-size: 1rem !important;
}
.ql-editor h5{
    font-size: 1.25rem !important;
}
.ql-editor h4{
    font-size: 1.5rem !important;
}
.ql-editor h3{
    font-size: 1.75rem !important;
}
.ql-editor h2{
    font-size: 2rem !important;
}

:host  ::ng-deep p {
    margin-bottom: 0;
}