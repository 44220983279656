// Configuration
@import "../node_modules/bootstrap/scss/functions";

$ml-switch-height:23px;
$ml-switch-width:39px;
$movielearning-purple-light:#a251bd;
// here we override bootstrap with the MovieLearning color
$purple:  #a251bd;
$menu-text:#50366e;
$green: #1ebe55;
$primary: $purple;

$base-image-path: 'https://courses.move-e-learning.com/static/img';

$border-radius: .4rem;
$border-radius-sm: .3rem;
$border-radius-lg: .5rem;
$modal-header-border-color: #dee2e640;
$base-font-size: 1px;                                                          // equals around 14.4px at 0.9em
$base-font-size-content: 14px;

$link-decoration: none !important;

$ml-loading-dimensions: 80px;

// #fonts
$font-base-ubuntu: 'Ubuntu', Helvetica, Arial, Verdana, sans-serif;
$font-content-karla: 'Karla', Helvetica, Arial, Verdana, sans-serif;
$font-content-open-sans: 'Open Sans', Helvetica, Arial, Verdana, sans-serif;

$font-family-base: $font-base-ubuntu;

$font-family-base:            var(--#{$variable-prefix}font-sans-serif) !default;


// special tags
$special-tag-outer-width-base: 140px;
$special-tag-base-circle-dimensions: 100px;
$special-tag-full-circle-dimensions: 15px;
$special-tag-thin-circle-dimensions: 16px;
$special-tag-broad-circle-dimensions: 16px;

$special-tag-modal-top-padding: 20px;
$special-tag-popup-width: 150px;

// #pesky level colors
$level-0-bg-color: #c6c7c8;
$level-1-bg-color: #e2001a;
$level-2-bg-color: #ee7f00;
$level-3-bg-color: #ffcc00;
$level-4-bg-color: #41a62a;
$level-5-bg-color: #009ee0;
$level-6-bg-color: #004b93;
$level-7-bg-color: #5b1f78;

//## Gray and brand colors for use across Bootstrap.
$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

$brand-primary:         darken(#428bca, 6.5%) !default; // #337ab7
$brand-success:         #5cb85c !default;
$brand-info:            #5bc0de !default;
$brand-warning:         #f0ad4e !default;
$brand-danger:          #d9534f !default;

$font-base-color: #333;;

$level-color-list: $level-0-bg-color, $level-1-bg-color, $level-2-bg-color, $level-3-bg-color, $level-4-bg-color, $level-5-bg-color, $level-6-bg-color, $level-7-bg-color;

@import "../node_modules/bootstrap/scss/variables";